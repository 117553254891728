import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ToplineMobile from '@rambler-components/topline-mobile';
import ToplineDesktop from '@rambler-components/topline';

import SearchFormMobile from '@components/SearchForm/mobile';

import '@rambler-components/topline/styles.css';
import '@rambler-components/topline-mobile/styles.css';

import { actions as userActions } from '@redux/modules/user';

interface IToplineProps {
    isMobile: boolean;
}

const authParams = {
    type: '',
    theme: '',
    texts: '',
    utm_source: '',   // eslint-disable-line
    utm_medium: '',   // eslint-disable-line
    utm_campaign: '', // eslint-disable-line
    utm_content: '',  // eslint-disable-line
    utm_term: '',     // eslint-disable-line
    registration_link: 'https://id.rambler.ru/login-20/registration', // eslint-disable-line
};

const Topline: React.FC<IToplineProps> = ({ isMobile }) => {
    const dispatch = useDispatch();

    const handleChangeUserState = (state) => {
        dispatch(userActions.UPDATE_AUTH(state));
    };

    const [isSticky, setIsSticky] = useState(false);
    const [isOpenTopline, setIsOpenTopline] = useState(false);

    useEffect(() => {
        if (!isMobile) return () => {};

        const burgerOpen = document.querySelectorAll(
            '[data-cerber-topline-mob="burger::open"]',
        )[0];
        const burgerClose = document.querySelectorAll(
            '[data-cerber-topline-mob="burger::close"]',
        )[0];

        burgerOpen.addEventListener('click', () => setIsOpenTopline(true));
        burgerClose.addEventListener('click', () => setIsOpenTopline(false));

        // cleaner
        return () => {
            burgerOpen.removeEventListener('click', () => setIsOpenTopline(!isOpenTopline));
            burgerClose.removeEventListener('click', () => setIsOpenTopline(!isOpenTopline));
        };
    }, [isMobile, isOpenTopline]);

    return (
        isMobile ? (
            <ToplineMobile
                projectCode="top100"
                withSearch={false}
                // @ts-ignore // потому что crm_details стоит как обязательный параметр,
                // но нам он не нужен
                authFrameOptions={authParams}
                stickySearch={false}
                onSticky={sticky => setIsSticky(sticky)}
                stickyCustomComponent
                customComponent={
                    <SearchFormMobile isSticky={isSticky} isOpenTopline={isOpenTopline} />
                }
            />
        ) : (
            <ToplineDesktop
                projectCode="top100"
                // @ts-ignore // после обновления версии types можно будет убрать
                withoutLogo
                // @ts-ignore
                authFrameOptions={authParams}
                visibleMenuItems={8}
                onUserStateChange={handleChangeUserState}
            />
        )
    );
};

export default Topline;
