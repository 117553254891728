import React from 'react';
import FooterRamblerUI from '@rambler-components/footer';
import Logo from '@components/Logo';
import { menuLinksMobile, menuLinksDesktop } from './data';

import '@rambler-components/footer/styles.css';
import s from './Footer.pcss';

interface IFooterProps {
    isMobile: boolean;
    type?: 'inverse' | 'white';
    hiddenLogo?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ isMobile, type = '', hiddenLogo = false }) => {
    const defaultType = isMobile ? 'inverse' : 'white';
    const ruiType = type || defaultType;
    return (
        <>
            {!hiddenLogo && (<Logo className={s.logo} />)}
            <FooterRamblerUI
                type={ruiType}
                className={s.footer}
                menuLinks={isMobile ? menuLinksMobile : menuLinksDesktop}
                ageLimit
                yearCopyright={new Date().getFullYear()}
            />
        </>
    );
};

export default Footer;
