import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { QueryString } from '@tools/queryString';
import Button from '@components/Button';
import Svg from '@components/Svg';
import { eventsYm } from '@tools/events';

import s from './SearchMobile.pcss';

interface IProps {
    searchValue: string;
    history?: History;
    isSticky?: boolean;
    isOpenTopline?: boolean;
}

export const SearchFormMobile: React.FC<IProps & RouteComponentProps> = ({
    history,
    searchValue,
    isSticky = false,
    isOpenTopline = false,
}) => {
    const [value, setSearchValue] = useState(searchValue);
    const [canClearSearch, setCanClearSearch] = useState(value.trim().length > 0);

    const classNames = cn(s.root, {
        [s.openTopline]: isOpenTopline,
    });

    const classNamesForm = cn(s.searchForm, {
        [s.searchFormSticky]: isSticky,
    });

    useEffect(() => {
        setSearchValue(searchValue);
        setCanClearSearch(searchValue.length > 0);
    }, [searchValue]);

    const action = async () => {
        // Из значения вначале вырезается все включая и до '://'
        const newSearchValue = value.replace(/^.*:\/\//i, '');
        const queryParams = QueryString.getString({ query: newSearchValue });

        history.push({
            pathname: '/search',
            search: queryParams,
        });

        setSearchValue(newSearchValue);
        setCanClearSearch(false);
    };

    const handleSearchField = ({ target }) => {
        setSearchValue(target.value);
        setCanClearSearch(target.value.length > 0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        eventsYm.clickSearchButton();

        action();
    };

    const handleCleanerField = () => {
        setSearchValue('');
        setCanClearSearch(false);
        history.push({
            search: '',
        });
    };

    const handleDecodeUrl = () => {
        try {
            return decodeURIComponent(value);
        } catch (error) {
            return value;
        }
    };

    return (
        <div className={classNames}>
            <div className={classNamesForm}>
                <form
                    className={s.form}
                    onSubmit={handleSubmit}
                >
                    <input
                        className={s.field}
                        type="search"
                        name="searchValue"
                        value={handleDecodeUrl()}
                        onChange={handleSearchField}
                        placeholder="Сайты в каталоге"
                    />
                    {canClearSearch && (
                        <Button
                            onClick={handleCleanerField}
                            className={s.buttonCleaner}
                            type="reset"
                        >
                            <Svg className={s.buttonCleanerIcon} name="icon-input-cleaner" />
                        </Button>
                    )}
                    <Button
                        className={s.buttonSearch}
                        secondary
                        type="submit"
                    >
                        <Svg className={s.buttonSearchIcon} name="icon-mobile-search" />
                    </Button>
                </form>
            </div>
        </div>
    );
};
