import React, {
    cloneElement,
    isValidElement,
    ReactNode,
    ReactElement,
    MouseEvent,
} from 'react';
import cn from 'classnames';

import s from './Button.pcss';

interface IButtonProps {
    children: ReactNode;
    className?: string;
    href?: string;
    target?: string;
    onClick?: (event: MouseEvent) => void;
    container?: ReactElement;
    icon?: ReactElement;
    iconClassName?: string;
    disabled?: boolean;
    id?: string;
    rounded?: boolean;
    secondary?: boolean;
    primary?: boolean;
    noBordered?: boolean;
    type?: 'reset' | 'button' | 'submit';
    dataPromo?: string;
    dataAttributes?: Record<string, string>;
}

const Button: React.FC<IButtonProps> = ({
    className,
    href,
    target,
    onClick,
    container,
    children,
    icon,
    iconClassName,
    id,
    rounded,
    secondary,
    primary,
    noBordered,
    disabled,
    type,
    dataPromo = '',
    dataAttributes = {},
}) => {
    const cnButton = cn(s.root, {
        [className]: className,
        [s.rounded]: rounded,
        [s.secondary]: secondary,
        [s.primary]: primary,
        [s.noBordered]: noBordered,
    });

    const props = {
        className: cnButton,
        disabled: disabled || null,
        href,
        onClick,
        id,
        ...(dataPromo ? { 'data-promo': dataPromo } : {}),
        ...(dataAttributes.name ? { [dataAttributes.name]: dataAttributes.value } : {}),
    };

    const childElements = (
        <>
            {icon && cloneElement(icon, {
                className: cn(s.icon, iconClassName),
            })}
            {children}
        </>
    );

    if (href) {
        return (
            <a {...props} target={target || null} rel="noopener noreferrer">
                {childElements}
            </a>
        );
    }

    if (isValidElement(container)) {
        return cloneElement(container, props, childElements);
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button {...props} type={type}>
            {childElements}
        </button>
    );
};

Button.defaultProps = {
    children: 'Нажми на меня',
    className: '',
    href: null,
    target: '',
    onClick: () => {},
    id: null,
    disabled: false,
    type: 'button',
};

export default Button;
