export const menuLinksDesktop = [
    {
        title: 'Размещение рекламы',
        href: 'http://reklama.rambler.ru/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Правила пользования и конфиденциальность',
        href: 'http://help.rambler.ru/legal/top100/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Обратная связь',
        href: 'http://help.rambler.ru/feedback/top100/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Документация',
        href: 'https://top-100-writer.gitbook.io/top100-documentation/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Помощь',
        href: 'http://help.rambler.ru/top100/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Блог',
        href: 'https://medium.com/@ramblertop',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Вакансии',
        href: 'http://rambler-co.ru/jobs',
        rel: 'nofollow',
        target: '_blank',
    },
];

export const menuLinksMobile = [
    {
        title: 'Правила пользования',
        href: 'http://help.rambler.ru/legal/top100/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Обратная связь',
        href: 'http://help.rambler.ru/feedback/top100/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Документация',
        href: 'https://top-100-writer.gitbook.io/top100-documentation/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Помощь',
        href: 'http://help.rambler.ru/top100/',
        rel: 'nofollow',
        target: '_blank',
    },
    {
        title: 'Блог',
        href: 'https://medium.com/@ramblertop',
        rel: 'nofollow',
        target: '_blank',
    },
];
