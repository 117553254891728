import React, { MouseEvent, memo } from 'react';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';

import s from './Svg.pcss';

interface ISvgProps{
    name: string;
    className?: string;
    onClick?: (event: MouseEvent) => void;
}

const Svg: React.FC<ISvgProps> = ({ name, className, onClick }) => {
    const svgClass = cn(s.root, (!className ? s.sizes : className));
    return (
        <svg onClick={onClick} className={svgClass}>
            <use xlinkHref={`#${name}`} />
        </svg>
    );
};

function areEqual(prevProps, nextProps) {
    return isEqual(prevProps, nextProps);
}

Svg.defaultProps = {
    name: '',
    className: '',
    onClick: () => {},
};

export default memo(Svg, areEqual);
